<template>
  <div class="main-visual">
    <video
      src="/images/main/visual.mp4"
      poster="/images/main/visual-thumb.jpg"
      loop
      autoplay
      muted
      playsinline
      class="main-visual__video"
    />
    <div class="main-visual__inner">
      <div class="main__inner">
        <div class="for-pc">
          <div class="container">
            <h1>1심 2심 판심! 판심법무법인</h1>
            <div class="content_txt">
                22년 퇴임 서울법대 판사출신변호사와<br>
                24,23,22년 퇴임 검사출신변호사가<br>
                의뢰인을 위해 전력을 다합니다.
            </div>
            <div class="bottom-container1">
              <div class="border-container">
                <ul class="school-line-list border border1">
                  <li class="school-line">
                    <img src="/images/main/main_icon_1.svg" alt="">
                    <p>서울법대 사시합격</p>
                  </li>
                  <li class="school-line">
                    <img src="/images/main/main_icon_2.svg" alt="">
                    <p>수원/전주지법 판사출신</p>
                  </li>
                  <li class="school-line">
                    <img src="/images/main/main_icon_3.png" alt="">
                    <p>서울북부/인천/청주지검 검사출신</p>
                  </li>
                  <li class="school-line">
                    <img src="/images/main/main_icon_4.png" alt="">
                    <p>대한변협 등록 형사법 전문</p>
                  </li>
                  <li class="school-line">
                    <div class="image-set">
                      <img src="/images/main/main_icon_5.png" alt="">
                      <img src="/images/main/main_icon_6.svg" alt="">
                    </div>
                    <p>등 TV출연 변호사</p>
                  </li>
                </ul>
              </div>
              <div class="bottom-container2">
                <div class="image-container">
                  <div v-for="n in 4" :key="n"><img :src="`/images/main/main0${n}.png`" alt="" /></div>
                </div>
                <<div class="last-container">-->
                  <img src="/images/main/searchbox.jpg" alt="" />
                  <div class="low_txt pc">
                    당신의 변호사는 누구입니까?<br>
                    나의 변호사는 판심입니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="for-mobile">
          <div class="container">
            <h1>1심 2심 판심! 판심법무법인</h1>
            <div class="content_txt">
                22년 퇴임 서울법대 판사출신변호사와<br>
                24,23,22년 퇴임 검사출신변호사가<br>
                의뢰인을 위해 전력을 다합니다.
            </div>
            <div class="bottom-container1">
              <div class="border-container">
                <ul class="school-line-list border border1">
                  <li class="school-line">
                    <img src="/images/main/main_icon_1.svg" alt="">
                    <p>서울법대 사시합격</p>
                  </li>
                  <li class="school-line">
                    <img src="/images/main/main_icon_2.svg" alt="">
                    <p>수원/전주지법 판사출신</p>
                  </li>
                  <li class="school-line">
                    <img src="/images/main/main_icon_3.png" alt="">
                    <p>서울북부/인천/청주지검 검사출신</p>
                  </li>
                  <li class="school-line">
                    <img src="/images/main/main_icon_4.png" alt="">
                    <p>대한변협 등록 형사법 전문</p>
                  </li>
                  <li class="school-line">
                    <div class="image-set">
                      <img src="/images/main/main_icon_5.png" alt="">
                      <img src="/images/main/main_icon_6.svg" alt="">
                    </div>
                    <p>등 TV출연 변호사</p>
                  </li>
                </ul>
              </div>
              <div class="bottom-container2">
                <div class="image-container">
                  <div v-for="n in 4" :key="n"><img :src="`/images/main/main0${n}.png`" alt="" /></div>
                </div>
                <div class="last-container">
                  <img src="/images/main/searchbox.jpg" alt="" />
                  <div class="low_txt pc">
                    당신의 변호사는 누구입니까?<br>
                    나의 변호사는 판심입니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.main-visual {
  width: 100%;
  height: 0;
  padding-top: 170%;
  overflow: hidden;
  position: relative;

  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    display: block;
    overflow: hidden; 

    &[poster] {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    background-image: url(/images/main/visual-bg.jpg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .75;
  }

  &__inner {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tit {
    width: 100%;
    max-width: 220px;

    &__ani {
      transform: scale(1.4);
      opacity: 0;
      visibility: hidden;
      animation: zoomOut 1.3s ease-out forwards;

      &:nth-child(3) {
        animation-delay: .2s;
        clear: both;
      }

      &:nth-child(1) {
        animation-delay: .4s;
      }

      &:nth-child(2) {
        animation-delay: .6s;
      }
    }
  }
}

.main__inner {
  .for-pc {
    .container {
      color: white;
      margin: 0 auto;
      padding: 30px 50px;

      .text-center {
        text-align: center;
      }

      h1 {
        font-size: 4em;
        font-weight: bold;
        background: linear-gradient(166deg, #f7f5cc, #f7f5cc, #bf8255, #bc8863);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -ms-background-clip: text;
        -ms-text-fill-color: transparent;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 700;
      }

      h1 + .content_txt {
        color: #fff;
        font-size: 2.3em;
        margin-bottom: 30px;
        text-align: center;
        font-weight: 500;
        line-height: 5rem;
      }

      .bottom-container1 {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 30px;
        margin-top: 30px;

        .border-container {
          .school-line-list {
            display: flex;
            flex-direction: column;
            gap: 15px;

            li {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 15px;
              padding-block: 6px;
              color: #fff;
              background: rgb(0 0 0 / 50%);
              height: 100%;
              border: 2px solid #732f3c;
              border-radius: 6px;
              min-height: 64px;

              .image-set {
                display: flex;
                gap: 10px;

                img {
                  height: 32px;
                }
              }
              
              img {
                width: auto;
                height: 48px;
              }

              p {
                font-size: 1.4em;
                color: #fff;
              }
            }
          }
        }
      }

      .bottom-container2 {
        .image-container {
          display: flex;
          justify-content: space-between;
          gap: 30px;

          img {
            width: 100%;
          }
        }

        .last-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 20px;
          margin-top: 30px;

          img {
            width: 45%;
          }

          .low_txt {
            text-align: end;
            font-family: serif;
            font-weight: bold;
            color: #fff;
            font-size: 2.3em;
          }
        }
      }
    }
  }

  .for-mobile {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .for-pc .bottom-container1,
  .for-pc .bottom-container2,
  .for-mobile .bottom-container1,
  .for-mobile .bottom-container2 {
    animation: fadeIn 2s ease-in-out forwards;
  }

  @media (max-width: 1200px) {
    .for-pc {
      display: none !important;
    }

    .for-mobile {
      display: block;
      .container {
        & > h1 {
          font-size: 3rem;
          font-weight: bold;
          background: linear-gradient(166deg, #f7f5cc, #f7f5cc, #bf8255, #bc8863);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -ms-background-clip: text;
          -ms-text-fill-color: transparent;
          margin-bottom: 10px;
          text-align: center;
          font-weight: 700;
        }

        & > h1 + .content_txt {
          color: #fff;
          font-size: 1.35em;
          margin-bottom: 15px;
          text-align: center;
          font-weight: 700;
          line-height: 3rem;
        }

        .bottom-container1 {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-top: 20px;

          .border-container {
            .school-line-list {
              display: flex;
              flex-direction: column;
              gap: 6px;

              li {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                padding-block: 2px;
                color: #fff;
                background: rgb(0 0 0 / 50%);
                border: 2px solid #732f3c;
                border-radius: 6px;
                min-height: 40px;

                .image-set {
                  display: flex;
                  gap: 10px;

                  img {
                    height: 26px;
                  }
                }
                
                img {
                  width: auto;
                  height: 26px;
                }

                p {
                  font-size: 1.3em;
                  color: #fff;
                }
              }
            }
          }
        }

        .bottom-container2 {
          .image-container {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            img {
              width: 100%;
            }
          }

          .last-container {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .main-visual {
    height: 100vh;
    padding-top: 0;

    &__tit {
      max-width: 778px;
    }
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.4);
    opacity: 0;
    visibility: hidden;
  }
  100% {        
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
}
</style>
